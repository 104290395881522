@use '~nes.css/css/nes';

.item {
  @extend .nes-btn;
  @extend .is-warning;

  padding: 1em;
  margin: 0.35em;
  font-size: 1.15em;
}
