@import '~nes.css/css/nes';

.field {
  text-align: left;
  margin-bottom: 10px;

  .select {
    @extend .nes-select;
  }
}
