@use '~nes.css/css/nes';
@import '~@fortawesome/fontawesome-free/css/all.css';

html,
body {
  overflow-x: hidden;
  height: 100%;
}

body {
  &.noscroll {
    position: fixed;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
