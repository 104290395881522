.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  padding-top: 0.5em;
}

.actions {
  display: flex;
  margin-bottom: 0.5em;

  .main {
    color: green;
  }
  .disabled {
    color: grey !important;
  }

  button:not(:first-child) {
    margin-left: 0.2em;
  }
}
