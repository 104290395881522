@import '~nes.css/css/nes';

.iconButton {
  @extend .nes-btn;
  display: flex;
  width: 1.3em;
  height: 1.3em;
  align-items: center;
  justify-content: center;

  &.disabled {
    @extend .is-disabled;
  }

  font-size: 5em;
}
