@import '~nes.css/css/nes';
@import './common.scss';

.dictation {
  @extend .section;
  .input {
    display: flex;
    justify-content: center;
    textarea {
      @extend .nes-textarea;
      font-size: 2.5em;
      max-width: 80%;
      width: 20em;

      &.uppercase {
        text-transform: uppercase;
      }

      &.lowercase {
        text-transform: lowercase;
      }
    }
  }
}
