.board {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &.uppercase {
    text-transform: uppercase;
  }

  &.lowercase {
    text-transform: lowercase;
  }
}
